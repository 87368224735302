<template>
  <div class="peer-container">
    <Top></Top>
     <Fix></Fix>
     <div class="bread-box">
        <div class="bread-card">
          <el-breadcrumb class="bread-card-crumb" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path:'/recome',query:{goodsName:'AI商标注册'}}">AI商标注册</el-breadcrumb-item>
            <el-breadcrumb-item>查询同行</el-breadcrumb-item>
          </el-breadcrumb>
         </div>
      </div>
     <div class="peer-box">
         <div class="peer-box-top">
            <span>商标搜索</span>
            <input type="text" placeholder="请输入商标或公司名称" v-model="peerWord"><img :src="require('/static/images/searchbutton.png')" alt="" @click="searchpeer">
        </div>
        <div class="peer-box-title">
            <div :class="chosepeer">
                <div class="div1" @click="findpeer('like')">近似商标</div>
                <div class="div2" @click="findpeer('excat')">精准查询</div>
            </div>
        </div>
        <div class="peer-box-list" v-show="datashow">
            <div v-for="(v,k) in peerList" :key="k" class="peer-box-list-detail">
                <div class="peer-box-list-detail1">
                    <img :src="v.trademarkImgUrl" alt="">
                    <span>{{v.comName}}</span>
                </div>
                <div>
                    <div class="peer-box-list-bigtype" :key="n" v-for="(m,n) in v.TrademarkList">
                        <div>
                            <el-checkbox class="bigtype-checkbox" @change="selectfirst(m.isSelect1,k,n)" v-model="m.isSelect1"></el-checkbox>
                            <span class="big-span1">第{{m.oneCate}}类 {{m.oneCateName}}</span>
                            <span class="big-span2" @click="stretch1(m.code)">{{m.trandemarkStatus}}</span>
                            <i :class="secondshow==isTags(m.code)? 'el-icon-caret-bottom':'el-icon-caret-right'"></i>
                        </div>
                        <div v-for="(item,index) in m.twoCateList" :key="index" v-show="isTags(m.code)">
                            <div class="peer-box-list-second">
                                <el-checkbox class="bigtype-checkbox" v-model="item.isSelect2" @change="selectsecond(item.isSelect2,k,n,index)" ></el-checkbox>
                                <span class="second-span">{{item.twoCate}} {{item.twoCateName}}</span>
                            </div>
                            <div class="peer-box-list-third">
                                <div v-for="(d,t) in item.threeList" :key="t">
                                    <div>{{d.threeCate}} {{d.threeCateName}}</div>
                                    <!-- <div>x</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="peer-box-bottom" v-show="datashow">
            <div>
                <el-checkbox class="bigtype-checkbox" v-model="allcheck" @change="choseall"></el-checkbox>
                <span>全选</span>
                <i class="el-icon-delete"></i>
                <span>删除</span>
                <i class="el-icon-star-off"></i>
                <span>收藏</span>
            </div>
            <div @click="typeSuggest">类别推荐</div>
        </div>
        <div class="peer-noData" v-show="!datashow">
            <img :src="require('/static/images/nosearch.png')" alt="">
            <p>暂无数据</p>
        </div>
        <div class="peer-box-page">
            <!-- <div class="block">
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage1"
                :page-size="100"
                layout="total, prev, pager, next"
                :total="1000">
                </el-pagination>
            </div> -->
        </div>
     </div>
     <Foot></Foot>
  </div>    
</template>

<script>
import Top from '../ui/top.vue';
import Foot from '../ui/foot.vue';
import Fix from '../ui/fix.vue';
export default {
  name: 'peer',
  data(){
    return{ 
      chosepeer:'like',
      peerWord:'',
      peerList:[],
      star:[],
      radom:[],
      datashow:false,
      firstid:[],
      secondshow:true,
      allcheck:false
    }
  },
  watch:{
           

  },
  components: {
       Top,
      Foot,
      Fix
  },
  mounted(){
    this.peerWord = this.$route.query.word;
    if(this.peerWord != ''){
        this.searchpeer()
    }
  },
  created(){
    //   console.log(this.$store.state.brandName);
    //   console.log(this.$store.state.imgUrl);
    //   console.log(this.$store.state.typeface);
    //   console.log(this.$store.state.colorType);
  },
  methods:{
      tip(type,msg){
          return this.$message({
              type:type,
              message:msg,
              showClose:true,
              duration:3000
          })
      },
      choseall(){
          if(this.allcheck == true){
            //   console.log(1111)
              for(var i = 0;i<this.peerList.length;i++){
                for(var j=0;j<this.peerList[i].TrademarkList.length;j++){
                    this.peerList[i].TrademarkList[j].isSelect1 = true
                    for(var m =0;m<this.peerList[i].TrademarkList[j].twoCateList.length;m++){
                        this.peerList[i].TrademarkList[j].twoCateList[m].isSelect2 = true
                        
                    }
                }
            }
          }else{
            //   console.log(222222)
              for(var i = 0;i<this.peerList.length;i++){
                for(var j=0;j<this.peerList[i].TrademarkList.length;j++){
                    this.peerList[i].TrademarkList[j].isSelect1 = false
                    for(var m =0;m<this.peerList[i].TrademarkList[j].twoCateList.length;m++){
                        this.peerList[i].TrademarkList[j].twoCateList[m].isSelect2 = false
                        
                    }
                }
            }
          }
      },
      selectfirst(isSelect1,index,k){
          if(isSelect1 == true){
              for(var i =0;i<this.peerList[index].TrademarkList.length;i++){
                  for(var j=0;j<this.peerList[index].TrademarkList[k].twoCateList.length;j++){
                      this.peerList[index].TrademarkList[k].twoCateList[j].isSelect2 = true;
                  }
                }
              this.peerList[index].TrademarkList[k].isSelect1 = true; 
              var all=[]
              for(var i=0;i<this.peerList.length;i++){
                  for(var j =0;j<this.peerList[i].TrademarkList.length;j++){
                      all.push(this.peerList[i].TrademarkList[j].isSelect1);
                      for(var m = 0;m<this.peerList[i].TrademarkList[j].twoCateList.length;m++){
                          all.push(this.peerList[i].TrademarkList[j].twoCateList[m].isSelect2);
                      }
                  }
              } 
              if(all.indexOf(false)==-1){
                  this.allcheck = !this.allcheck;
              }  
          }else{
               for(var i =0;i<this.peerList[index].TrademarkList.length;i++){
                  for(var j=0;j<this.peerList[index].TrademarkList[k].twoCateList.length;j++){
                      this.peerList[index].TrademarkList[k].twoCateList[j].isSelect2 = false;
                  }
                }
                this.peerList[index].TrademarkList[k].isSelect1 = false; 
                this.allcheck = false;
          }
      },
      selectsecond(isSelect2,index,k,n){
          if(isSelect2 == true){
            //   console.log(123)
              this.peerList[index].TrademarkList[k].twoCateList[n].isSelect2 =true;
              var m_index = []
              for(var i =0;i<this.peerList[index].TrademarkList[k].twoCateList.length;i++){
                  if(this.peerList[index].TrademarkList[k].twoCateList[i].isSelect2 ==true){
                      m_index.push(this.peerList[index].TrademarkList[k].twoCateList[i].isSelect2);
                  }
              }
             if(m_index.length ==this.peerList[index].TrademarkList[k].twoCateList.length){
                 this.peerList[index].TrademarkList[k].isSelect1 = true;
             }
             var all=[]
              for(var i=0;i<this.peerList.length;i++){
                  for(var j =0;j<this.peerList[i].TrademarkList.length;j++){
                      all.push(this.peerList[i].TrademarkList[j].isSelect1);
                      for(var m = 0;m<this.peerList[i].TrademarkList[j].twoCateList.length;m++){
                          all.push(this.peerList[i].TrademarkList[j].twoCateList[m].isSelect2);
                      }
                  }
              } 
              if(all.indexOf(false)==-1){
                  this.allcheck = !this.allcheck;
              } 
            //  console.log(this.peerList)
          }else{
            //    console.log(11111)
                this.peerList[index].TrademarkList[k].twoCateList[n].isSelect2 =false;
                this.peerList[index].TrademarkList[k].isSelect1 = false;
                this.allcheck = false;
          }
      },
      typeSuggest(){
          const newArr = JSON.parse(JSON.stringify(this.peerList));
          let otherArr = [];
          for(var i = 0;i<newArr.length;i++){
                otherArr = otherArr.concat(newArr[i].TrademarkList);
            }
            
            var all=[]
            for(var i =0; i<otherArr.length;i++){
                all.push(otherArr[i].isSelect1);
                for(var j = 0;j<otherArr[i].twoCateList.length;j++){
                    all.push(otherArr[i].twoCateList[j].isSelect2)
                }
            }
              if(all.indexOf(true)==-1){
                 this.tip('error','请选择您需要选择的类别')
              }else{
                  for(var i = otherArr.length - 1; i >= 0; i--){
                    for(var j = otherArr[i].twoCateList.length - 1; j >= 0; j--){
                        if(otherArr[i].twoCateList[j].isSelect2 == false||otherArr[i].twoCateList[j].threeList.length==0){
                        otherArr[i].twoCateList.splice(j,1)
                        if(otherArr[i].twoCateList.length == 0){
                                otherArr.splice(i,1)
                            }
                        }
                    }
                 }
                 console.log(otherArr)
                 let dataArr = [];
                        otherArr.map(mapItem => {
                          if (dataArr.length == 0) {
                              dataArr.push({ code: mapItem.code, isSelect1: true, oneCate:mapItem.oneCate,oneCateName:mapItem.oneCateName ,List: mapItem.twoCateList})
                          } else {
                            let res = dataArr.some((item,index)=> {
                              if (item.oneCate == mapItem.oneCate) {
                                item.List.push(mapItem.twoCateList[index]);
                                return true;
                              }
                            })
                            if (!res) {//如果没找相同日期添加一个新对象
                               dataArr.push({ code: mapItem.code, isSelect1: true, oneCate:mapItem.oneCate,oneCateName:mapItem.oneCateName ,List: mapItem.twoCateList })
                            }
                          }
                        })
                       console.log(dataArr) 
              } 

      },
      stretch1(val){
            if (this.firstid.includes(val)) {
              for(var i =0;i<this.firstid.length;i++){
                  if(val == this.firstid[i]){
                      this.firstid.splice(i,1)
                  }
              }
          }else{
              this.firstid.push(val);
          }
      },
       isTags(val){
          if (this.firstid.includes(val)) {
            return true;
        } else {
            return false;
        }
      },
    findpeer(val){
        this.chosepeer = val
    },
    searchpeer(){
        if(this.peerWord!=''){
            const loading = this.$loading({
              lock: true,
              text: '请求中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.6)'
            });
            this.$http.post(this.GLOBAL.new_url+'/trademark/service/ai/getPeer',{
            userCode:this.$cookie.getCookie('u_id'),
            text:'果老',
            queryType:'2',
            index:1,
            size:10
            },{
                        headers:{
                            AuthToken:this.$cookie.getCookie('token'),
                            'X-Request-Origin':'xcx'
                        }
                                    }).then(res=>{
            // console.log(res)
            if(res.data.code== "200"){
                this.peerList = res.data.data.list;
                this.datashow =true;
                if(this.peerList.length!=0){
                    if(this.$route.query.word!=''){
                        this.$http.post(this.GLOBAL.new_url+'/trademark/service/ai/getRates',{
                            text:'果老'
                        },{
                        headers:{
                            AuthToken:this.$cookie.getCookie('token'),
                            'X-Request-Origin':'xcx'
                        }
                                    }).then(res=>{
                                        // console.log(res)
                                        if(res.data.code == '200'){
                                            for(var i in res.data.data){
                                                this.star.push({code:i,sscore:Number(res.data.data[i])})
                                            }
                                            for(var i = 0;i<this.star.length;i++){
                                                if(Number(this.star[i].code<1000)){
                                                    this.star[i].code = '0'+this.star[i].code
                                                }
                                            }
                                            // console.log(this.star)
                                            this.$http.post(this.GLOBAL.new_url+'/trademark/service/ai/queryRandomIssuingRate',{},{
                                                headers:{
                                                    AuthToken:this.$cookie.getCookie('token'),
                                                    'X-Request-Origin':'xcx'
                                                }}).then(res=>{
                                                    if(res.data.code == '200'){
                                                        this.radom = res.data.data;
                                                        var _this=this;
                                                        for(var i = 0;i<this.peerList.length;i++){
                                                            for(var j=0;j<this.peerList[i].TrademarkList.length;j++){
                                                                _this.$set(this.peerList[i].TrademarkList[j], 'isSelect1', false);
                                                                for(var m =0;m<this.peerList[i].TrademarkList[j].twoCateList.length;m++){
                                                                    _this.$set(this.peerList[i].TrademarkList[j].twoCateList[m], 'isSelect2', false);
                                                                    for(var k = 0;k<this.star.length;k++){
                                                                        if(this.star[k].code == this.peerList[i].TrademarkList[j].twoCateList[m].twoCate){
                                                                            this.peerList[i].TrademarkList[j].twoCateList[m].sscore = this.star[k].sscore
                                                                            this.peerList[i].TrademarkList[j].twoCateList[m].rate = Number(this.radom[this.star[k].sscore]);
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        loading.close();
                                                     
                                                    }
                                            })
                                        }
                                    })
                                    console.log(this.peerList)
                    }else{
                        var _this=this;
                        for(var i = 0;i<this.peerList.length;i++){
                            for(var j=0;j<this.peerList[i].TrademarkList.length;j++){
                                _this.$set(this.peerList[i].TrademarkList[j], 'isSelect1', false);
                                for(var m =0;m<this.peerList[i].TrademarkList[j].twoCateList.length;m++){
                                    _this.$set(this.peerList[i].TrademarkList[j].twoCateList[m], 'isSelect2', false);
                                    this.peerList[i].TrademarkList[j].twoCateList[m].sscore = 0;
                                    this.peerList[i].TrademarkList[j].twoCateList[m].rate = 0;
                                }
                            }
                        }
                    }
                    
                }
                
            }

        })
        }
    },
  }
}

</script>

<style scoped>
.bread-box{
    width: 100%;
    height: 40px;
    background-color: #f8f8f8;
  }
  .bread-card{
    width: 1200px;
    height: 28px;
    margin: 0 auto;
    padding-top:12px;
  }
   .peer-box{
       background: #FAFAFA;
   }
   .peer-box-top{
        width: 1200px;
        height: 50px;
        padding-top: 38px;
        margin: 0 auto 48px;
    }
    .peer-box-top>span{
        font-size: 36px;
        font-weight: 600;
        color: #EB5E00;
        margin:0 32px;
        vertical-align: middle;
    }
    .peer-box-top>input{
        width: 622px;
        height: 42px;
        border: solid 1px #d7d8d9;
        outline: none;
        font-size: 16px;
        padding-left: 18px;
        color: #999999;
        vertical-align: middle;
    }
    .peer-box-top>img{
        vertical-align: middle;
        cursor: pointer;
    }
    .peer-box-title{
        height: 40px;
        border-bottom:2px solid #EB5E00;
    }
    .peer-box-title>div{
        width: 1200px;
        margin: 0 auto;
        height: 40px;
    }
    .peer-box-title>div>div{
        float: left;
        width: 120px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        color: #000000;
        cursor: pointer;
    }
    .peer-box-title>div>div:nth-of-type(1){
        margin-left: 40px;
    }
    .like .div1,.excat .div2{
        color: #ffffff;
       background: #EB5E00;
    }
    .peer-box-list{
        width: 1200px;
        padding-top: 40px;
        margin: 0 auto;
    }
    .peer-box-list>div:last-child{
        margin-bottom: 0;
    }
    .peer-box-list-detail{
        width: 832px;
        padding:40px 318px 40px 50px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px #F2F2F2;
        margin-bottom: 30px;
    }
    .peer-box-list-detail1{
        margin-bottom: 30px;
    }
    .peer-box-list-detail1>img{
        width: 100px;
        height: 100px;
        vertical-align: middle;
        border: 1px solid #D8D8D8;
    }
    .peer-box-list-detail1>span{
        vertical-align: middle;        
        font-size: 20px;
        margin-left: 30px;
        color: #000000;
    }
    .peer-box-list-bigtype{
        margin-bottom: 15px;
    }
    .peer-box-list-bigtype>div{
        margin-bottom: 12px;
    }
    .bigtype-checkbox{
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }
    .big-span1{
        font-size: 18px;
        vertical-align: middle;
        margin:0 20px 0 14px;
        color:#333333;
    }
    .big-span2{
        vertical-align: middle;
        font-size: 14px;
        color: #000000;
        cursor: pointer;
    }
    .el-icon-caret-bottom{
        vertical-align: middle;
    }
    .peer-box-list-second{
        margin-left: 14px;
        margin-bottom: 9px;
    }
    .second-span{
        font-size: 16px;
        font-weight: 400;
        vertical-align: middle;
        color: #333333;
        margin-left: 14px;
    }
    .peer-box-list-third{
        width: 818px;
        margin-left: 14px;
        /* border:1px solid red; */
        overflow: hidden;
    }
    .peer-box-list-third>div{
      float: left;
      height: 33px;
      padding-left: 8px;
      border: dotted 1px #d6d6d6;
      margin: 0 20px 8px 0;
  }
  .peer-box-list-third>div>div{
      float: left;
  }
  .peer-box-list-third>div>div:nth-of-type(1){
      height: 33px;
      text-align: center;
      line-height: 33px;
      font-size: 14px;
      font-weight: 300;
      padding-right: 8px;
      color: #333333;
      font-size: 14px;
      font-weight: 300;
  }
  .peer-box-list-third>div>div:nth-of-type(2){
      width: 35px;
      height: 33px;
      border-left:1px dotted #d6d6d6;
      background-color: #f5f5f5;
      font-size: 20px;
      color: #d6d6d6;
      text-align: center;
      cursor: pointer;
  }
  .peer-box-bottom{
      width: 1136px;
      height: 40px;
      margin:1px auto 0;   
      padding:14px 14px 14px 50px;
      background: #FFFFFF;
      box-shadow: 0px 4px 10px 0px #F2F2F2;
  }
  .peer-box-bottom>div:nth-of-type(1){
      float: left;
      padding-top: 9px;
  }
  .peer-box-bottom>div:nth-of-type(2){
      float: right;
      width: 146px;
      height: 40px;
      background: #F96009;
      border-radius: 3px;
      cursor: pointer;
      text-align: center;
      line-height: 40px;
      font-size: 16px;    
      color: #FFFFFF;
  }
  .peer-box-bottom>div>span{
    font-size: 16px;
    margin: 0 32px 0 12px;
    color: #333333;
  }
  .peer-box-page{
      width: 1200px;
      margin: 0 auto;
      padding: 40px 0 80px;
  }
  .peer-box-page>div{
      text-align: center;
  }
  .peer-noData{
      width: 1200px;
      height: 400px;
      margin: 100px auto 0;
      text-align: center;
  }
  .el-icon-caret-right{
        vertical-align: middle!important;
   }
</style>
<style>
  .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
      font-weight: normal;
  }
  .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link:hover{
      color:#303133;
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #EB5E00;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #EB5E00;
    border-color: #EB5E00;
  }
  
</style>
